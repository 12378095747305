var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.title[_vm.$i18n.locale])+" ")])]),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.typeList,"loading":_vm.loading,"pagination":false,"row-key":function (record) { return record.id; }},scopedSlots:_vm._u([{key:"name",fn:function(item){return [(item.group != 'statistics')?_c('router-link',{staticClass:"td-post-item",attrs:{"to":{
          name: 'items-detail',
          params: {
            item_group: item.group,
          },
        },"tag":"span"}},[_vm._v(" "+_vm._s(item.name[_vm.$i18n.locale])+" ")]):_c('router-link',{staticClass:"td-post-item",attrs:{"to":{
          name: 'statistic',
        },"tag":"span"}},[_vm._v(" "+_vm._s(item.name[_vm.$i18n.locale])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }