<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="typeList"
      :loading="loading"
      :pagination="false"
      :row-key="(record) => record.id"
    >
      <!-- <template slot="id" slot-scope="item">
        <b>{{ item.id }}</b>
      </template> -->
      <template slot="name" slot-scope="item">
        <router-link
          v-if="item.group != 'statistics'"
          :to="{
            name: 'items-detail',
            params: {
              item_group: item.group,
            },
          }"
          class="td-post-item"
          tag="span"
        >
          {{ item.name[$i18n.locale] }}
        </router-link>
        <router-link
          v-else
          :to="{
            name: 'statistic',
          }"
          class="td-post-item"
          tag="span"
        >
          {{ item.name[$i18n.locale] }}
        </router-link>
      </template>
    </a-table>
  </a-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data ()  {
    return {
      loading: false,
      columns: [
        // {
        //   width: "50px",
        //   key: "id",
        //   title: "Id",
        //   scopedSlots: { customRender: "id" },
        //   fixed: "left",
        // },
        {
          title: this.$t("TagsColTitle"),
          key: "name",
          scopedSlots: { customRender: "name" },
          width: "90%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("items", ["typeList"]),
  },
};
</script>
